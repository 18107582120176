import RGBToHex from './rgbToHex'
import dragElement from './dragElement'
import {pushingShape, locAdd, showStore, getLength, changeColor, deleteShape } from '../modules/store';


export class Rectangle {
  constructor(width, height, uid, topPosition, leftPosition, backgroundColor, parentSelector, ...classes) {
    this.width = width;
    this.height = height;
    this.uid = uid;
    this.topPosition = topPosition;
    this.leftPosition = leftPosition;
    this.backgroundColor = backgroundColor;
    this.classes = classes;
    this.parent = document.querySelector(parentSelector);
  }
  
  render() {
    const colorPicker = document.querySelector('.color-picker'),
          el = document.createElement('div');

    if (this.classes.length) {
      this.classes.forEach( className => el.classList.add(className));
    }

    el.innerHTML = `<canvas id=${this.uid} style='width:${this.width}; height:${this.height}; border:${this.border}; background-color:${this.backgroundColor};'></canvas>`;
    
    el.style.cssText = `
      top:${this.topPosition};
      left:${this.leftPosition};
    `;
    this.parent.append(el);


    document.querySelector('.btn_clear').addEventListener('click', () => {
      newShapes.forEach((shape) => {
        shape.classList.remove('selected');
      })
    })
  
    const newShapes = document.querySelectorAll('.new-shape canvas');
  
    newShapes.forEach((item) => {
      item.addEventListener('click', () => {  
        newShapes.forEach((item) => {
          item.classList.remove('selected');
        })        
        item.classList.add('selected');   
        colorPicker.value = RGBToHex(item.style.backgroundColor);
      })
    })
  
    document.addEventListener('keydown', function (event) {
      if (event.key === 'd') {  
        newShapes.forEach(item => {
          if (item.classList.contains('selected')) {
            deleteShape(item.getAttribute('id'));
            item.parentNode.remove();
          };
        })        
      }
      if (event.key === 'l') {
        document.body.style = '';
      }
    })  
    dragElement(el, this);    
  }
}

const createShape = (selector) => {
  
  const shape = document.querySelector(selector);

  shape.addEventListener('click', () => {    
    const newFigure = new Rectangle(
      '100px',
      '100px',
      getLength(),
      '50%',
      '50%',
      '#b3b3b3',
      '.workspace',
      'new-shape'
    );

    pushingShape(newFigure);
    newFigure.render();
  });
  
  const btnSave = document.querySelector('.btn_save');
  btnSave.addEventListener('click', () => {  
    locAdd();
  });

};

const colorPicker = document.querySelector('.color-picker');

colorPicker.addEventListener('change', () => {  
  const newShapes = document.querySelectorAll('.new-shape canvas');
  console.log(newShapes);

  newShapes.forEach((item) => {
    for (let i = 0; i < newShapes.length; i++) {        
      if (newShapes[i].classList.contains('selected')) {
        newShapes[i].style.backgroundColor = colorPicker.value;
        newShapes[i].classList.remove('selected');
        changeColor(newShapes[i].getAttribute('id'), colorPicker.value);
      }
    }
  });
  showStore();  
});

export default createShape;