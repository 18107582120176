import {localStorageAdd, localStorageChange } from './localStorage';

let arr_shapes = [];

function pushingShape(el) {
  arr_shapes.push(el);
}

function locAdd() {
  localStorageAdd(arr_shapes);
}

function showStore() {
  console.log(arr_shapes);
}

function changeColor(id, color) {
  arr_shapes.forEach(item => {
    if (id == item.uid) {
      item.backgroundColor = color;
    }
  })
}

function getLength() {
  return arr_shapes.length;
}

function deleteShape(id) {

  arr_shapes.forEach((item, i) => {
    if (id == item.uid) {
      arr_shapes.splice(i, 1);
    }
  })

  localStorage.removeItem('shapes' + id);
  console.log('shapes' + id);

  
}

export {pushingShape, locAdd, showStore, changeColor, getLength, deleteShape}