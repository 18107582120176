import {Rectangle } from './createShape';
import {pushingShape, getLength} from '../modules/store';

const localStorageAdd = (arr) => {
  arr.forEach((item, i) => {
    localStorage.setItem ('shapes'+ i, JSON.stringify(item));
    console.log(localStorage.getItem('shapes'+ i));
  });
}

const localStorageChange = (arr) => {
  arr.forEach((item, i) => {
    localStorage.setItem ('shapes'+ i, JSON.stringify(item));
  });
}

const localStorageLoad = () => {
  for(let key in localStorage) {
    let tmp = JSON.parse (localStorage.getItem(key));

    if (tmp) {
      const newFigure = new Rectangle(
        tmp.width,
        tmp.height,
        getLength(),
        tmp.topPosition,
        tmp.leftPosition,
        tmp.backgroundColor,
        '.workspace',
        'new-shape'
      );
  
      pushingShape(newFigure);
      newFigure.render();
    }
  }
}

export  { localStorageAdd, localStorageChange, localStorageLoad };